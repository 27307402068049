import * as React from 'react';
import { useTranslation } from 'react-i18next';

const MatomoTrackingConfiguration: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('legal');

  const [optedOut, setOptedOut] = React.useState<boolean>();

  const optedInOutTranslationKey = optedOut ? 'opted-out' : 'opted-in';
  const translationKey =
    typeof optedOut === 'undefined' || typeof window._paq !== 'object'
      ? 'blocked'
      : optedInOutTranslationKey;

  const refreshOptedOut = () => {
    if (window._paq) {
      window._paq.push([
        // eslint-disable-next-line prettier/prettier
        function (this) { // NOSONAR S1172: Unused function parameters should be removed
          setOptedOut(this.isUserOptedOut());
        },
      ]);
    }
  };

  React.useLayoutEffect(() => {
    if (typeof optedOut === 'undefined') {
      refreshOptedOut();
    }
  });

  return (
    <div>
      <p className="mb-3 whitespace-pre-line xl:mb-3">{t(`${translationKey}.text`)}</p>
      {typeof optedOut === 'boolean' && window && window._paq && (
        <p className="mt-3 xl:mt-3">
          <input
            type="checkbox"
            id="optout"
            className="mr-2 h-4 w-4 cursor-pointer md:mr-4"
            checked={!optedOut}
            onChange={() => {
              if (optedOut) {
                window._paq.push(['forgetUserOptOut']);
              } else {
                window._paq.push(['optUserOut']);
              }
              refreshOptedOut();
            }}
          />
          <label htmlFor="optout" className="cursor-pointer">
            {t(`${translationKey}.checkbox`)}
          </label>
        </p>
      )}
    </div>
  );
};

export default MatomoTrackingConfiguration;
