import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MdxLink } from 'gatsby-theme-i18n';
import { PageContextProps } from '../utils/graphql';
import MatomoTrackingConfiguration from './analytics/MatomoTrackingConfiguration';
import DefaultLayout from './layouts/default-layout';

const components = {
  a: MdxLink,
  MatomoTrackingConfiguration,
};

type MdxLayoutProps = {
  pageContext: PageContextProps;
  children: React.ReactNode;
};

const MdxLayout: React.FC<MdxLayoutProps> = ({ pageContext, children }) => (
  <DefaultLayout
    headTitle={pageContext.frontmatter?.title ?? ''}
    headDescription={pageContext.frontmatter?.description}
    wrapInContainer
  >
    <main className="prose px-4 pb-4 xl:prose-lg xs:px-8 sm:px-12 md:px-20 md:pb-8 lg:px-44 lg:pb-12">
      <h1>{pageContext.frontmatter?.title ?? ''}</h1>
      <MDXProvider components={components}>{children}</MDXProvider>
    </main>
  </DefaultLayout>
);

export default MdxLayout;
